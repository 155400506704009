<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-date-picker
            v-model="filter.peorid"
            type="daterange"
            value-format="timestamp"
            @change="getData"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="openExportSizeInput">导出</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="#" align="center" width="55"/>
        <el-table-column
          prop="locale_name"
          label="监测点"
          width="140"
          show-overflow-tooltip
          header-align="center">
        </el-table-column>
        <el-table-column
          prop="mn_last"
          label="设备编号"
          width="160"
          show-overflow-tooltip
          header-align="center"
          align="center"
        />
        <el-table-column
          prop="locale_addr"
          label="地址"
          width="240"
          show-overflow-tooltip
          header-align="center"
        />

        <el-table-column
          prop="dist"
          label="站点距离(m)"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="online_num"
          label="在线天数"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="excess_num"
          label="超标天数"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          prop="c_emissions_avg_online"
          label="在线日平均油烟浓度"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="c_emissions_max"
          label="在线日最大油烟浓度"
          align="center"
          header-align="center"
        />
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>
    <alarm-view-dialog ref="alarmViewDialog" @on-refresh="getData"/>
  </section>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'OtherLocale',
  data() {
    return {
      loading: false,
      datalist: {},
      filter: {
        peorid: [],
        page: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50]
      },
      filterRecord: {
        page: 1,
        size: 20
      },
    }
  },
  computed: {
    ...mapState(["clientHeight"]),
  },
  mounted() {
    this.getData()
  },
  methods: {
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleExport(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handleExport(num) {
      const param = {
        page: this.filter.page,
        start_time: this.filter.peorid && this.filter.peorid.length > 1 ? this.filter.peorid[0] / 1000:"",
        end_time: this.filter.peorid && this.filter.peorid.length > 1 ? this.filter.peorid[1] / 1000 + 86399:"",
        size: num,
        is_download: true
      }

      this.$get("admin/list/setting/locale", param).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
      })
    },
    handleRefresh(clear = true) {
      if (clear) {
        this.filter.page = 1
      }
      this.getData()
    },
    getData() {
      this.loading = true
      const param = {
        page: this.filter.page,
        size: this.filter.size,
        start_time: this.filter.peorid && this.filter.peorid.length > 1 ? this.filter.peorid[0] / 1000:"",
        end_time: this.filter.peorid && this.filter.peorid.length > 1 ? this.filter.peorid[1] / 1000 + 86399:"",
      }
      this.$get("admin/list/setting/locale", param).then(res => {
        this.datalist.content = res.list
        this.datalist.total = res.count
        this.loading = false
      })
    },

    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
  }
}
</script>

<style lang="scss" scoped>

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}

.single-img > img {
  width: 160px;
  height: 160px;
}

.view-img {
  width: 178px;
  height: 178px;
  margin-right: 10px;
}

.remove-img {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
  background: rgba(0, 0, 0, .5);
  transition: transform .3s;
  -webkit-transition: transform .3s;
}

.remove-img:hover {
  cursor: pointer;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.locale-progress {
  margin-top: 12px;
  margin-right: 40px;
}
</style>
